// Color names from https://yamada-colors.app/

$colors: (
    "text": #272727,
    "white": #FFFFFF,
    "black": #000000,
    "gray-06": #F0F0F0,
    "gray-25": #C0C0C0,
    "gray-50": #7F7F7F,
    "gray-81": #303030,
    "raftsman": #3E619B,
);

/** Font stack adapted from
  * https://github.com/system-fonts/modern-font-stacks?tab=readme-ov-file#system-ui
  */

/* $fonts-serif: 'EB Garamond', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif; */
$fonts-serif: 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052, serif;

$fonts-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

$fonts-header: $fonts-sans;

$fonts-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Courier New", monospace;

$breakpoint-mobile: 599px;
$breakpoint-desktop: 600px;
// $breakpoint-footer: 800px;

/* --- COLORS --------------------------------------------------------------- */

html { background-color: map-get( $colors, "gray-81" ); }

body { color: map-get( $colors, "text" ); }

.bg-white { background-color: map-get( $colors, "white" ); }
.bg-grey { background-color: map-get( $colors, "gray-06" ); }

.fill-white { fill: map-get( $colors, "white" ); }

/* --- TYPOGRAPHY ----------------------------------------------------------- */

body { font-family: $fonts-serif; font-size: 17px; font-weight: 400; }
input, textarea { font-family: $fonts-sans; font-size: 17px; font-weight: 400; }
pre, code, th, td { font-family: $fonts-mono; font-size: 14px; }

pre { padding: 0.5rem 1em; overflow-x: auto; line-height: 1.75rem; }
pre, code { background-color: map-get( $colors, "gray-06" ) }
code { padding: 2px 5px; }
pre code { background-color: transparent; padding: 0; }

p, li, dd, dt { line-height: 1.75rem; }
h1, h2 { font-weight: normal; }
h1, h2, h3, h4, h5, .title { line-height: 1.25em; font-family: $fonts-sans; font-weight: 500 !important; }
h1 { font-size: 32px; font-weight: 300; margin-top: 0; margin-bottom: 32px; }
h2 { font-size: 24px; border-bottom: 1px solid map-get( $colors, "gray-25" ); }
h2, h3 { margin-top: 3rem; }
h4 { margin-bottom: 1rem; }
p { margin: 0 0 1rem 0; text-align: justify; hyphens: auto; }
.title { font-size: 24px; }

/* --- MODIFIERS ------------------------------------------------------------ */

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.float-left { float: left; margin: 0 2rem 1rem 0; }
.float-right { float: right; margin: 0 0 1rem 2rem; }
.clear-float { clear: both; }

.has-border { border: 1px solid map-get( $colors, "black" ); }

.hidden-mobile { display: none !important; }

/* --- LAYOUT --------------------------------------------------------------- */

body { margin: 0; padding: 0; }
.body-width { max-width: 850px; margin: 0 auto; padding: 0 1em; }

#top__nav, #footer {
    font-family: $fonts-sans;
}

#top__nav, #top__nav a,
footer, footer a { color: map-get( $colors, "white" ); }

#top__nav a,
footer,
footer a { color: map-get( $colors, "gray-25" ); }

#top__nav a:hover,
footer a:hover { color: map-get( $colors, "white" ); }

#top__nav {

    padding: 1.5rem 0;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .col {
        flex-grow: 1;
    }

    .flex-shrink {
        flex: 0;
    }

}

.top__nav__mobile__show {
    padding: 6px 9px;
    border: none;
    border-radius: 4px;
    background-color: map-get( $colors, "raftsman" );
    color: map-get( $colors, "white" );
    text-decoration: none;
    cursor: pointer;
}

#top__nav__mobilelinks .nav {
    margin-top: 1rem;
}

.header__logo {

    flex-grow: 1;
    text-align: center;

    a {
        position: relative;
        left: -28px;
        display: inline-block;
        width: 96px;
    }

}

.nav {

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    a { text-decoration: none; }

}

@media (min-width: 450px) {
    #top__nav {
        flex-direction: row;
        justify-content: space-between;
    }
    .header__logo a { margin-bottom: 0; }
}

#content {

    padding: 2rem 1rem;
    img { margin-bottom: 1rem; max-width: 100%; }

    p:last-of-type,
    p:last-of-type img {
        margin-bottom: 0;
    }

    article header { margin-bottom: 1rem; }
}

footer {

    font-size: 12px;
    padding: 2em 0;

    .footer__inner {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .footer__nav {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem 2rem;
        margin-bottom: 1rem;

        a {
            font-size: 18px;
        }
    }

}

.hero {
    background-color: #000000;
    /* NOTE: If you change this image URL, be sure to update the preload URL in /_includes/head.html */
    background-image: url('/images/layout/gaelan-lloyd-2024-05.webp');
    background-size: cover;
    background-position-x: 56%;
    height: 20rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    h1 {
        color: map-get( $colors, "white" );
        text-transform: uppercase;
        display: flex;
        font-family: 'Rubik', $fonts-sans;
        font-size: 2rem;
        letter-spacing: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        line-height: 100%;
        text-shadow: 2px 2px 10px #001A29;
    }
}

@media only screen and (min-width: $breakpoint-desktop) {

    .hero {
        height: 35rem;
        /* background-image: url('/images/layout/gaelan-lloyd-2024-05.webp'); */
        background-position: center;

        h1 {
            font-size: 4rem;
            margin-bottom: 4rem;
        }
    }

    .nav {
        justify-content: end;
    }

    .header__logo {
        text-align: left;
    }

}

/* --- ELEMENTS ------------------------------------------------------------- */

hr { border: none; border-top: 1px solid #000000; margin: 2rem 0; }

input, textarea { background-color: map-get( $colors, "white" ); border: 1px solid map-get( $colors, "gray-25" ); border-radius: 6px; }
input, textarea { padding: 0.5em; margin: 0.5em 0; width: 300px; resize: none; }
input[type=submit] { color: map-get( $colors, "text" ); padding: 0.5em 2em; border: none; width: auto; font-weight: bold; background-color: map-get( $colors, "gray-25" ); cursor: pointer; }
input[type=submit]:hover { background-color: map-get( $colors, "gray-81" ); color: map-get( $colors, "white" ); }

figure { margin: 0 0 2em 0; }
figcaption { font-size: 85%; }

blockquote { border-left: 3px dotted #C0C0C0; padding-left: 2rem; }

span.highlighted { background-color: #F9F973; padding: 0 0.25rem; }

table { border-collapse: collapse; margin-bottom: 1rem; }
table.table { width: 100%; }
table.center-contents td { text-align: center; }
th, td { border: 1px solid map-get( $colors, "gray-50" ); padding: 0.5em 1em; text-align: left; }
th { background-color: map-get( $colors, "gray-06" ); border-bottom: 2px solid #000000; }
table.table-vertical th { border-right: 2px solid #000000; border-bottom: 1px solid map-get( $colors, "gray-50" ); }

ul { list-style-type: square; }
li { margin-bottom: 1rem; }
ul ul, ol ol { margin-top: 1rem; }

ul.plain { list-style-type: none; padding-left: 0; }

ul.inline { display: flex; flex-direction: row; flex-wrap: wrap; gap: 0.5rem; list-style-type: none; padding: 0; }
ul.inline li { margin-bottom: 0; }

ul.nav { padding-left: 0; margin: 0; }
ul.nav li { display: inline-block; margin: 0; }
ul.nav li:not(:last-child) { margin-right: 2rem; }

ul.post-list {

    .post {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
    }

    .post-date { width: 5rem; min-width: 5rem; }
}

ul.nav a,
footer a { text-decoration: none; }

dt, dl { margin-top: 1rem; margin-bottom: 1rem; }
dt { font-weight: bold; }

/* --- ALERTS ----------- */

.alert {
    padding: 1rem;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 1rem;

    *:last-child { margin-bottom: 0; }
}

.alert-notice {
    border-color: #FF3333;
    background-color: #FFE6E6;
    pre, code { background-color: #ECC6D9; }
}

.alert-info {
    border-color: #66CCFF;
    background-color: #B3E6FF;
    pre, code { background-color: #99CCFF; }
}

/* --- DESKTOP ----------------------------------------------------------- */

@media only screen and (min-width: $breakpoint-desktop) {

    .hidden-desktop { display: none !important; }
    .hidden-mobile { display: inherit !important; }

    #content { padding: 3rem 1rem 2rem 1rem; }

    #footer { padding: 3rem 0; }

    .header__logo {
        flex: inherit;
        a { position: inherit; }
    }

    .nav { gap: 1.5rem; }

    ul.post-list .post {
        flex-direction: row;
    }

}

/* --- MOBILE ONLY ---------------------------------------------------------- */

@media only screen and (max-width: $breakpoint-mobile) {

    #top__nav .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    ul.post-list .post-date {
        font-size: 75%;
        font-weight: bold;
        text-transform: uppercase;
    }

}
